import { Button } from '@aether/components'
import { ProductSizeGuideType } from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductOptionValue } from './ProductOptionValue'

const FieldSet = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  ':not(:last-child)': {
    marginRight: '10px',
  },
})

const StyledSizeGuideButton = styled(Button, {
  $aetherFont: 'ui03',
  padding: '0',
})

const ProductOptionLegend = styled('legend', {
  padding: '0 0 2px 0',
  width: '100%',
})

const ProductLabelContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ProductLabel = styled('span', {
  $aetherFont: 'ui01',
  color: '$black',
})

const LowStockLabel = styled('span', {
  $aetherFont: 'ui01',
  color: '$red',
  marginLeft: '$16',
})

const ErrorMessage = styled('span', {
  display: 'block',
  $aetherFont: 'ui01',
  color: '$red',
  marginTop: '$12',
})

export type OptionValueProps = {
  optionId: string
  id: string
  title: string
  selected: boolean
  disabled: boolean
}

export type ProductOptionProps = {
  id: string
  values?: OptionValueProps[]
  onChange?: (id: string, groupId: string) => void
  disabled?: boolean
  disableLegend?: boolean
  name: string
  sizeGuide?: ProductSizeGuideType
  openSizeGuide: () => void
  showErrors: boolean
  isLowStock?: boolean
  isEvent?: boolean
  availableAmount?: number
}

export const ProductOptionGroup: FC<ProductOptionProps> = ({
  values,
  onChange,
  id,
  name,
  openSizeGuide,
  showErrors,
  sizeGuide,
  disableLegend = false,
  isLowStock,
  isEvent,
  availableAmount,
}) => {
  const { t } = useTranslation('product')
  const isSizeVariant = name === 'Size'
  // TODO: find the way to translate varian option label as well
  const variantNameAriaLabel = t('variantNameLabel', { value: name })
  const variantEmptyErrorMessage = t('variantEmptyErrorMessage', {
    value: name,
  })

  const noneIsSelected = values?.every((value) => !value.selected)

  const currentVariant = values?.find((value) => value.selected === true)

  return (
    <fieldset>
      {!disableLegend && (
        <ProductOptionLegend>
          <ProductLabelContainer>
            <ProductLabel>
              {name}: {currentVariant ? currentVariant.title : ''}
              {isLowStock &&
                (isEvent ? (
                  <LowStockLabel>
                    {t('lowStockCounter', {
                      value: availableAmount,
                    })}
                  </LowStockLabel>
                ) : (
                  <LowStockLabel>{t('lowStock')}</LowStockLabel>
                ))}
            </ProductLabel>
            {isSizeVariant && sizeGuide && (
              <StyledSizeGuideButton
                appearance="defaultLink"
                onClick={openSizeGuide}
              >
                {t('sizeGuideButtonLabel')}
              </StyledSizeGuideButton>
            )}
          </ProductLabelContainer>
        </ProductOptionLegend>
      )}
      <FieldSet
        role={'radiogroup'}
        aria-label={variantNameAriaLabel}
        aria-invalid={noneIsSelected && showErrors}
        aria-errormessage={`errormessage-${id}`}
      >
        {values?.map((option) =>
          option ? (
            <ProductOptionValue
              {...option}
              key={`${id}_${option.id}`}
              onChange={onChange}
            />
          ) : null,
        )}
      </FieldSet>
      {noneIsSelected && showErrors && (
        <ErrorMessage id={`errormessage-${id}`} role="alert">
          {variantEmptyErrorMessage}
        </ErrorMessage>
      )}
    </fieldset>
  )
}
