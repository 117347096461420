import { styled } from '@aether/styles'
import React, { FC } from 'react'
import { OptionValueProps } from './ProductOptionGroup'
import { useTranslation } from 'react-i18next'

const Root = styled('div', {
  display: 'grid',
  position: 'relative',
  background: 'none',
  padding: '$0',
  margin: '10px 0 0 0',
})
const LabelWrap = styled('label', {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: '22px',
  borderRadius: '$r1',
  // Button size should be uniform 54x32px.
  minHeight: 32,
  minWidth: 54,
  padding: '$4 $12',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: '1px solid transparent',
  transition: 'background-color 250ms, color 250ms',
  position: 'relative',
  overflow: 'hidden',
  svg: { display: 'none' },
  variants: {
    selected: {
      // Selected is fill
      true: {
        border: '1px solid $black',
        backgroundColor: '$black',
        color: '$white',
      },
      // Unselected is black outline
      false: {
        backgroundColor: '$white',
        color: '$black',
        border: '1px solid $black',
      },
    },
    disabled: {
      // sold out is grey
      true: {
        backgroundColor: '$gray_light_third',
        border: '1px solid $gray_light_third',
        color: '$gray_medium',
        svg: {
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        },
      },
    },
    false: {
      '&:hover': {
        backgroundColor: '$black',
        color: '$white',
      },
    },
  },
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

export const ProductOptionValue: FC<
  OptionValueProps & {
    selected?: boolean
    disabled?: boolean
    onChange?: (id: string, groupId: string) => void
  }
> = ({ id, onChange, optionId, selected, title, disabled }) => {
  const { t } = useTranslation('product')
  const soldOutLabelTitle = t('soldOutLowerCase')

  const mixedId = `${optionId}_${id}`
  return (
    <Root>
      <InputRadio
        type={'radio'}
        role="radio"
        id={mixedId}
        required
        name={`${optionId} - ${id}`}
        value={id}
        onChange={() => (onChange ? onChange(id, optionId) : null)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onChange) {
            onChange(id, optionId)
          }
        }}
        checked={selected}
      />
      <LabelWrap
        selected={selected}
        disabled={disabled}
        htmlFor={mixedId}
        title={disabled ? soldOutLabelTitle : undefined}
      >
        {!selected && (
          <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <line
              x1="0"
              y1="100"
              x2="100"
              y2="0"
              strokeWidth="2"
              stroke="#757575"
            />
          </svg>
        )}
        {title}
      </LabelWrap>
    </Root>
  )
}
