import { FormEvent, useState } from 'react'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { Button, Loader } from '@aether/components'
import {
  newsletterSubscribe,
  subscribeToBackInStock,
} from '@aether/services/api-service'
import { validateEmail } from './helpers/validateEmail'
import { convertGidToId } from '@aether/utils'
import { ShopifyProductVariant } from '@aether/models'

const StyledForm = styled('form', {
  display: 'grid',
  gap: '$16',
})

const NotificationText = styled('p', {
  $aetherFont: 'body04',
  whiteSpace: 'pre-wrap',
  textTransform: 'uppercase',
})

const Input = styled('input', {
  margin: 0,
  $aetherFont: 'body01',
  borderRadius: '50px',
  display: 'block',
  height: '$buttonHeightL',
  padding: '0 24px',
  border: '1px solid',
  borderColor: '$black',
  color: '$black',
  appearance: 'none',
  '&::placeholder': {
    color: '$gray',
    textTransform: 'uppercase',
  },
  '&:focus': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    isActive: {
      true: {
        '&::placeholder': {
          opacity: 1,
          transition: 'opacity 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 10ms',
        },
      },
      false: {
        '&::placeholder': {
          opacity: 0,
          transition: 'none',
        },
      },
    },
    isInvalid: {
      true: {
        borderColor: '$red',
        color: '$red',
        '&:focus': {
          boxShadow: '0 0 0 3px $colors$red',
        },
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$gray_light',
      },
      false: {
        backgroundColor: '$white',
      },
    },
  },
  '@lg': {
    $aetherFont: 'body04',
  },
})

const FieldContainer = styled('div', {
  width: '100%',
})

const CheckInput = styled('input', {
  position: 'absolute',
  width: 1,
  height: 1,
  opacity: 0,
})

const CheckMark = styled('div', {
  marginRight: '8px',
  position: 'relative',
  border: '1px solid',
  borderColor: '$black',
  borderRadius: '$rMax',
  width: '28px',
  height: '28px',
  '&::after': {
    content: '',
    position: 'absolute',
    borderRadius: '$rMax',
    backgroundColor: '$black',
    width: '20px',
    height: '20px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    transition: 'opacity 150ms ease-in',
  },
  variants: {
    isInvalid: {
      true: {
        borderColor: '$red',
      },
    },
    checked: {
      true: {
        '&::after': {
          opacity: 1,
        },
      },
      false: {
        '&::after': {
          opacity: 0,
        },
      },
    },
  },
})

const Label = styled('label', {
  $aetherFont: 'body04',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  textTransform: 'uppercase',
  [`${Input}:focus + ${CheckMark}`]: {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    isInvalid: {
      true: {
        color: '$red',
        [`${Input}:focus + ${CheckMark}`]: {
          outline: '$red solid 3px',
          outlineOffset: '2px',
        },
      },
    },
  },
})

const StyledEmailWrapper = styled('div', {
  display: 'grid',
  gap: '$12',
})

const ErrorMessage = styled('span', {
  display: 'block',
  $aetherFont: 'ui01',
  color: '$red',
})

const StyledButton = styled(Button, {
  variants: {
    isSuccess: {
      true: {
        cursor: 'default',
      },
    },
  },
})

type FormState = {
  state: 'default' | 'invalid' | 'loading' | 'success'
  message: string
}

const DEFAULT_STATE: FormState = {
  state: 'default',
  message: '',
}

export const JoinWaitlistForm = ({
  currentVariant,
  setShowOptionsErrors,
}: {
  currentVariant: ShopifyProductVariant | undefined
  setShowOptionsErrors: (value: boolean) => void
}) => {
  const { t } = useTranslation('product')
  const [checked, setChecked] = useState<boolean>(false)
  const [email, setEmail] = useState('')
  const [formState, setFormState] = useState<FormState>(DEFAULT_STATE)
  const errorId = 'joinWaitlistError'

  const handleResponse = (res: Response) => {
    if (!res.ok) {
      return res.json().then((errorData) => {
        throw new Error(errorData.error || 'Failed to subscribe')
      })
    }
    return res.json()
  }

  const handleJoinWaitlist = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentVariant) {
      setShowOptionsErrors(true)
      return
    }
    if (formState.state === 'success') return
    if (!email) {
      setFormState({
        state: 'invalid',
        message: t('joinWaitlistEmailRequired') ?? '',
      })
      return
    }
    const isEmailValid = validateEmail(email)
    if (!isEmailValid) {
      setFormState({
        state: 'invalid',
        message: t('joinWaitlistInvalidEmail') ?? '',
      })
      return
    }
    setFormState({
      state: 'loading',
      message: '',
    })
    subscribeToBackInStock({
      email: email,
      variantId: convertGidToId(currentVariant.id),
    })
      .then((res) => handleResponse(res))
      .then(() => {
        if (checked) {
          newsletterSubscribe({ email: email })
            .then((res) => handleResponse(res))
            .then(() => {
              setFormState({
                state: 'success',
                message: t('joinWaitlistFormSuccess') ?? '',
              })
            })
            .catch(() => {
              setFormState({
                state: 'invalid',
                message: t('joinWaitlistFormError') ?? '',
              })
            })
        } else {
          setFormState({
            state: 'success',
            message: t('joinWaitlistFormSuccess') ?? '',
          })
        }
      })
      .catch(() => {
        setFormState({
          state: 'invalid',
          message: t('joinWaitlistFormError') ?? '',
        })
      })
  }

  const renderButtonContent = () => {
    switch (formState.state) {
      case 'loading':
        return <Loader />
      case 'success':
        return formState.message
      case 'invalid':
      case 'default':
      default:
        return t('joinWaitlist')
    }
  }

  return (
    <StyledForm onSubmit={handleJoinWaitlist}>
      {formState.state !== 'success' && (
        <>
          <NotificationText>
            {t('joinWaitlistNotificationText')}
          </NotificationText>
          <StyledEmailWrapper>
            <Input
              aria-label={t('joinWaitlistInputLabel') ?? ''}
              placeholder={t('joinWaitlistInputLabel') ?? ''}
              value={email}
              onChange={(e) => {
                setFormState(DEFAULT_STATE)
                setEmail(e.target.value)
              }}
            />
            {formState.state === 'invalid' && (
              <ErrorMessage id={errorId}>{formState.message}</ErrorMessage>
            )}
          </StyledEmailWrapper>
          <FieldContainer>
            <Label>
              <CheckInput
                aria-errormessage={errorId}
                aria-invalid={formState.state === 'invalid'}
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <CheckMark checked={checked} />
              {t('joinWaitlistCheckboxLabel')}
            </Label>
          </FieldContainer>
        </>
      )}
      <StyledButton
        appearance="badgeBlack"
        id="joinWaitlistButton"
        type="submit"
        isSuccess={formState.state === 'success'}
      >
        {!currentVariant ? t('selectSizeForWaitlist') : renderButtonContent()}
      </StyledButton>
    </StyledForm>
  )
}
